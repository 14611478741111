/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.6.10/dist/vue.min.js
 * - /npm/vuex@3.0.1/dist/vuex.min.js
 * - /npm/vue-i18n@8.14.0/dist/vue-i18n.min.js
 * - /npm/axios@0.19.0/dist/axios.min.js
 * - /npm/gsap@1.20.3/src/minified/TweenLite.min.js
 * - /npm/gsap@1.20.3/src/minified/TimelineLite.min.js
 * - /npm/scrollmagic@2.0.5/scrollmagic/minified/ScrollMagic.min.js
 * - /npm/scrollmagic@2.0.5/scrollmagic/minified/plugins/animation.gsap.min.js
 * - /npm/svg4everybody@2.1.9/dist/svg4everybody.min.js
 * - /npm/tiny-cookie@2.1.2/dist/tiny-cookie.min.js
 * - /npm/clipboard@2.0.4/dist/clipboard.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
